import { createTabeebHubAction } from '../services/actionCreators'

export const tabeeb = {
  // connection state
  onClose: createTabeebHubAction('ON_SIGNALR_CONNECTION_CLOSED'),
  onDisconnected: createTabeebHubAction('ON_SIGNALR_DISCONNECTED'),
  onReconnected: createTabeebHubAction('ON_SIGNALR_RECONNECTED'),
  onConnected: createTabeebHubAction('ON_SIGNALR_CONNECTED'),

  // notifications
  onContentCopied: createTabeebHubAction('ON_SIGNALR_CONTENT_COPIED'),
  onDeleteAdminNotification: createTabeebHubAction('ON_SIGNALR_DELETE_ADMIN_NOTIFICAION'),

  onAddNotification: createTabeebHubAction('ON_SIGNALR_ADD_NOTIFICATION'),
  onDeleteNotification: createTabeebHubAction('ON_SIGNALR_DELETE_NOTIFICATION'),
  onUpdateNotification: createTabeebHubAction('ON_SIGNALR_UPDATE_NOTIFICATION'),

  // chat
  onChatMessage: createTabeebHubAction('ON_SIGNALR_CHAT_MESSAGE'),
  onChatMessageUpdated: createTabeebHubAction('ON_SIGNALR_CHAT_MESSAGE_UPDATED'),

  // external api
  onExternalApiResponseReceived: createTabeebHubAction('ON_SIGNALR_EXTERNAL_API_RESPONSE_RECEIVED'),

  // forms
  onFormAttached: createTabeebHubAction('ON_SIGNALR_FORM_ATTACHED'),
  onFormDetached: createTabeebHubAction('ON_SIGNALR_FORM_DETACHED'),
  onFormUpdated: createTabeebHubAction('ON_SIGNALR_FORM_UPDATED'),

  onAnswersCreated: createTabeebHubAction('ON_SIGNALR_ANSWERS_CREATED'),
  onAnswerStatusUpdated: createTabeebHubAction('ON_SIGNALR_ANSWERS_UPDATED'),
  onAnswersCleared: createTabeebHubAction('ON_SIGNALR_ANSWERS_CLEARED'),
  onAnswerStatusStatisticsUpdated: createTabeebHubAction('ON_SIGNALR_ANSWER_STATUS_STATISTICS_UPDATED'),
  onAnswerStatusUpdatedByReviewer: createTabeebHubAction('ON_SIGNALR_ANSWER_STATUS_UPDATED_BY_REVIEWER'),

  onShowInfoMessage: createTabeebHubAction('ON_SIGNALR_SHOW_INFO_MESSAGE'),

  onFormRecordCreated: createTabeebHubAction('ON_SIGNALR_FORM_RECORD_CREATED'),
  onFormRecordDeleted: createTabeebHubAction('ON_SIGNALR_FORM_RECORD_DELETED'),

  // session folders
  onSessionFolderAdded: createTabeebHubAction('ON_SIGNALR_SESSION_FOLDER_ADDED'),
  onSessionFolderUpdated: createTabeebHubAction('ON_SIGNALR_SESSION_FOLDER_UPDATE'),
  onSessionFolderDeleted: createTabeebHubAction('ON_SIGNALR_SESSION_FOLDER_DELETED'),
  onPageSessionFolderUpdated: createTabeebHubAction('ON_PAGE_SESSION_FOLDER_UPDATED'),
  onPageFolderPermissionsUpdated: createTabeebHubAction('ON_SIGNALR_PAGE_FOLDER_PERMISSIONS_UPDATED'),
  onPageFolderPrivacyUpdated: createTabeebHubAction('ON_SIGNALR_PAGE_FOLDER_PRIVACY_UPDATED'),

  // folder user
  onUserRemovedFromSessionFolder: createTabeebHubAction('ON_SIGNALR_CLIENT_REMOVED_FROM_SESSION_FOLDER'),
  onUserChangedRole: createTabeebHubAction('ON_SIGNALR_CHANGED_ROLE'),

  // flash
  onFlashToggleValueUpdated: createTabeebHubAction('ON_SIGNALR_FLASH_TOGGLE_VALUE_UPDATED'),
  onFlashTurnedOn: createTabeebHubAction('ON_SIGNALR_FLASH_TURNED_ON'),

  // config
  onVideoBridgeSwitched: createTabeebHubAction('ON_SIGNALR_VIDEO_BRIDGE_SWITCHED'),

  // ai
  onAIObjectsCounterChanged: createTabeebHubAction('ON_SIGNALR_AI_OBJECTS_COUNTER_CHANGED'),

  onUniqueAIObjectUpdated: createTabeebHubAction('ON_SIGNALR_UNIQUE_AI_OBJECT_UPDATED'),

  // users
  onSessionUserJoined: createTabeebHubAction('ON_SIGNALR_SESSION_USER_JOINED'),

  onMediaUserLeft: createTabeebHubAction('ON_SIGNALR_MEDIA_USER_LEFT'),

  onRemoteChargeUpdated: createTabeebHubAction('ON_SIGNALR_REMOTE_CHARGE_UPDATED'),

  onImageDecreased: createTabeebHubAction('ON_SIGNALR_IMAGE_DECREASED'),
  onImageIncreased: createTabeebHubAction('ON_SIGNALR_IMAGE_INCREASED'),
  onZoomingValueUpdated: createTabeebHubAction('ON_SIGNALR_ZOOMING_VALUE_UPDATED'),

  onContentUserRoleUpdated: createTabeebHubAction('ON_CONTENT_USER_ROLE_UPDATED'),

  onUserLeaveContent: createTabeebHubAction('ON_SIGNALR_USER_LEAVE_CONTENT'),

  // content state
  onContentStateUpdated: createTabeebHubAction('ON_SIGNALR_CONTENT_STATE_UPDATED'),
  onNotifyContentDeleted: createTabeebHubAction('ON_SIGNALR_NOTIFY_CONTENT_DELETED'),

  onPresenterChanged: createTabeebHubAction('ON_SIGNALR_PRESENTER_CHANGED'),

  onCallStarted: createTabeebHubAction('ON_SIGNALR_CALL_STARTED'),
  onCallEnded: createTabeebHubAction('ON_SIGNALR_CALL_ENDED'),

  onRecordingStatusUpdate: createTabeebHubAction('ON_SIGNALR_RECORDING_STATUS_UPDATE'),
  onLocalRecordingOwnerControlRecording: createTabeebHubAction('ON_SIGNALR_LOCAL_RECORDING_OWNER_CONTROL_RECORDING'),

  onRemoveUserFromContent: createTabeebHubAction('ON_SIGNALR_REMOVE_USER_FROM_CONTENT'),

  onConferenceJoined: createTabeebHubAction('ON_SIGNALR_CONFERENCE_JOINED'),

  // conference
  onScreenSharingStarted: createTabeebHubAction('ON_SIGNALR_SCREEN_SHARING_STARTED'),
  onScreenSharingStopped: createTabeebHubAction('ON_SIGNALR_SCREEN_SHARING_STOPPED'),

  // reviewer
  onContentReworkRequested: createTabeebHubAction('ON_SIGNALR_CONTENT_REWORK_REQUESTED'),
  onReviewCreated: createTabeebHubAction('ON_SIGNALR_REVIEW_CREATED'),
  onReviewCompleted: createTabeebHubAction('ON_SIGNALR_REVIEW_COMPLETED'),
  onReviewRevoked: createTabeebHubAction('ON_SIGNALR_REVIEW_REVOKED'),
  onReviewResumed: createTabeebHubAction('ON_SIGNALR_REVIEW_RESUMED'),
  onReviewUpdated: createTabeebHubAction('ON_SIGNALR_REVIEW_UPDATED'),
  onContentReviewStatusChange: createTabeebHubAction('ON_SIGNALR_SESSION_REVIEW_STATUS_CHANGE'),
  onReviewFeedbackSent: createTabeebHubAction('ON_SIGNALR_REVIEW_FEEDBACK_SENT'),

  // gallery
  onPageNameChanged: createTabeebHubAction('ON_SIGNALR_PAGE_NAME_CHANGED'),
  updatePageName: createTabeebHubAction('ON_SIGNALR_UPDATE_PAGE_NAME'),
  onPresenterState: createTabeebHubAction('ON_SIGNALR_PRESENTER_STATE'),
  onPresenterAction: createTabeebHubAction('ON_SIGNALR_PRESENTER_ACTION'),
  onAssetUpdated: createTabeebHubAction('ON_SIGNALR_ASSET_UPDATED'),
  onNotifyContent: createTabeebHubAction('ON_SIGNALR_NOTIFY_CONTENT'),
  onPagesRestored: createTabeebHubAction('ON_SIGNALR_PAGES_RESTORED'),
  onPagesDeletedPermanently: createTabeebHubAction('ON_SIGNALR_PAGES_DELETED_PERMANENTLY'),
  onFoldersRestored: createTabeebHubAction('ON_SIGNALR_FOLDERS_RESTORED'),
  onFoldersDeletedPermanently: createTabeebHubAction('ON_SIGNALR_FOLDERS_DELETED_PERMANENTLY'),
  onAutodeskViewerStateChanged: createTabeebHubAction('ON_SIGNALR_AUTODESK_VIEWER_STATE_CHANGED'),
  onAssetCreated: createTabeebHubAction('ON_SIGNALR_ASSET_CREATED'),

  // annotations
  onAnnotation: createTabeebHubAction('ON_SIGNALR_ANNOTATION'),
  onAnnotationUpdate: createTabeebHubAction('ON_SIGNALR_ANNOTATION_UPDATE'),
  onAnnotationDeleted: createTabeebHubAction('ON_SIGNALR_ANNOTATION_DELETED'),
  onAnnotationReceived: createTabeebHubAction('ON_SIGNALR_ANNOTATION_RECEIVED'),
  onAnnotationRestored: createTabeebHubAction('ON_SIGNALR_ANNOTATION_RESTORED'),
  onUpdatePageAnnotations: createTabeebHubAction('ON_SIGNALR_UPDATE_PAGE_ANNOTATIONS'),
  onUpdatePagesAnnotations: createTabeebHubAction('ON_SIGNALR_UPDATE_PAGES_ANNOTATIONS'),
  onAnnotationsDeleted: createTabeebHubAction('ON_SIGNALR_ANNOTATIONS_DELETED'),

  // tracking
  onTrackingAdded: createTabeebHubAction('ON_SIGNALR_TRACKING_ADDED'),
  onTrackingDeleted: createTabeebHubAction('ON_SIGNALR_TRACKING_DELETED'),

  // reminders
  onReminderUpdated: createTabeebHubAction('ON_SIGNALR_REMINDER_UPDATED'),

  // calendar
  onCalendarEventCreated: createTabeebHubAction('ON_CALENDAR_EVENT_CREATED'),
  onCalendarEventDeleted: createTabeebHubAction('ON_CALENDAR_EVENT_DELETED'),
  onCalendarEventUpdated: createTabeebHubAction('ON_CALENDAR_EVENT_UPDATED'),

  // account
  onAccountDeleted: createTabeebHubAction('ON_ACCOUNT_DELETED'),

  // contentSharings
  onContentsLinkUpdated: createTabeebHubAction('ON_CONTENTS_LINK_UPDATED'),
  onContentsLinkDeleted: createTabeebHubAction('ON_CONTENTS_LINK_DELETED'),

  // model training
  onNotifyProject: createTabeebHubAction('ON_SIGNALR_NOTIFY_PROJECT'),

  // workflows
  onWorkflowFailed: createTabeebHubAction('ON_SIGNALR_WORKFLOW_FAILED'),
}

export const spreadsheetHub = {
  // azure recognition
  onTableRecognitionNotFound: createTabeebHubAction('ON_SIGNALR_TABLE_RECOGNITION_NOT_FOUND'),
  onTableRecognitionDone: createTabeebHubAction('ON_SIGNALR_TABLE_RECOGNITION_DONE'),
  onSpreadsheetImportSuccess: createTabeebHubAction('ON_SIGNALR_SPREADSHEET_IMPORT_SUCCESS'),
  onSpreadsheetImportFailed: createTabeebHubAction('ON_SIGNALR_SPREADSHEET_IMPORT_FAILED'),
}

export const healthDeviceHub = {
  onMeasurementAdded: createTabeebHubAction('ON_SIGNALR_MEASUREMENT_ADDED'),
}

export const autodeskHub = {
  onAutodeskUpdate: createTabeebHubAction('ON_SIGNALR_AUTODESK_UPDATE'),
}
