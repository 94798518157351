import PropTypes from 'prop-types'

import { DeleteOutlined } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'

import { IconButton } from '@tabeeb/uikit'

export interface EndAdornmentRemoveProps {
  onClick: () => void
}

const EndAdornmentRemove: React.FC<EndAdornmentRemoveProps> = ({ onClick }) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <InputAdornment position='end'>
      <IconButton onClick={onClick} onMouseDown={handleMouseDown} edge='end'>
        <DeleteOutlined color='error' />
      </IconButton>
    </InputAdornment>
  )
}

EndAdornmentRemove.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EndAdornmentRemove
