import { List, ListItem, styled } from '@mui/material'
import { Span } from '@tabeeb/uikit/components'

export const ListHeader = styled(Span)(({ theme }) => {
  return {
    fontWeight: theme.typography.fontWeightBold,
  }
})

export const StyledList = styled(List)(({ theme }) => {
  return {
    flex: 1,
    padding: theme.spacing(0, 2),
    overflow: 'hidden',

    '&:hover': {
      overflowY: 'auto',
    },
  }
})

export const StyledListItem = styled(ListItem)(({ theme }) => {
  return {
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(),
    border: '1px solid #ccc !important',
    paddingTop: 0,
    paddingBottom: 0,
    height: 39,
    width: 186,
    display: 'flex',
    justifyContent: 'space-between',
    background: '#fff',
  }
})
