import { combineActions, handleActions } from 'redux-actions'
import { callLeaved, jitsiInitialized } from '@tabeeb/modules/presentation/actions/conference'
import { leaveVideoCall, resetContentState } from '@tabeeb/shared/content/actions'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { notifyStartCall } from '@tabeeb/modules/presentation/actions/connection'

const initialState = false

export default handleActions(
  {
    [jitsiInitialized]() {
      return true
    },
    [combineActions(
      callLeaved,
      leaveVideoCall,
      resetContentState,
      notifyStartCall,
      signalrEvents[signalrConstants.tabeebHubName].onCallEnded
    )]() {
      return initialState
    },
  },
  initialState
)
