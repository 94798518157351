import { useFormikContext } from 'formik'
import { DialogActions, Button } from '@mui/material'

import PropTypes from 'prop-types'

const DialogActionsWithFormik = ({ onClose }) => {
  const { isSubmitting, dirty, isValid, submitForm, resetForm } = useFormikContext()

  const handleClose = () => {
    onClose()
    resetForm()
  }

  return (
    <DialogActions>
      <Button onClick={submitForm} disabled={!dirty || isSubmitting || !isValid}>
        Save
      </Button>
      {onClose && (
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
      )}
    </DialogActions>
  )
}

DialogActionsWithFormik.propTypes = {
  onClose: PropTypes.func,
}

export default DialogActionsWithFormik
