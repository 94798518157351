/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */

const deleteEmptyStringProperties = (obj: any) => {
  for (const key in Object.keys(obj)) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (value === '') {
        delete obj[key]
      }
    }
  }
}

export const removeTemporaryFields = (tenantConfig: any) => {
  // These values must not be stored in the config
  delete tenantConfig.supportsPrograms
  delete tenantConfig.name
  delete tenantConfig.isBillingEnabled
  delete tenantConfig.ftpHost
  delete tenantConfig.ftpUser
  delete tenantConfig.ftpPassword

  deleteEmptyStringProperties(tenantConfig)
}
