import { put, takeLatest, select, all, take } from 'redux-saga/effects'

import { muteVideo } from '@tabeeb/modules/presentationToolbar/actions/muteVideo'
import { muteAudio } from '@tabeeb/modules/presentationToolbar/actions/muteAudio'
import { presentationSelectors } from '@tabeeb/modules/presentation'
import { gridViewSelectors } from '@tabeeb/modules/gridView'
import { AUDIO_DEVICE, AUDIO_MUTED, VIDEO_DEVICE, VIDEO_MUTED } from '@tabeeb/modules/presentation/constants'
import { removeLocalTrack, trackStopped, updateLocalTrack } from '@tabeeb/modules/presentation/actions/tracks'
import * as jitsiTrackActions from '../actions/jitsiTrack'
import * as conferenceActions from '../actions/conference'
import * as trackService from '../services/trackService'
import * as connectionActions from '../actions/connection'
import { usersActions } from '../../../users'
import { accountSelectors } from '../../account'

function* addTrackToRoom(action) {
  const track = action.payload
  const jitsiRoom = yield select((state) => state.presentation.conference.room)

  if (!jitsiRoom) {
    track.dispose()
    return
  }

  jitsiRoom.addTrack(track)
}

function* detachTrack(action) {
  const track = action.payload
  const { containers } = track
  if (containers.length > 0) {
    for (const container of containers) {
      track.detach(container)
    }
  }
}

function* stopTrack(action) {
  const track = action.payload
  yield put(jitsiTrackActions.detachTrack(track))
  track.dispose()
  yield put(trackStopped())
}

function* attachVideo(action) {
  const userId = action.payload
  const tracksState = yield select((state) => state.presentation.tracks)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  const track = trackService.getTrack(tracksState, userId, currentUserId, VIDEO_DEVICE)
  if (!track) {
    return
  }

  const isLargeVideo = tracksState.largeVideoUser !== null && tracksState.largeVideoUser.id === userId
  const isGridView = yield select(gridViewSelectors.getIsGridViewActive)
  const isCallPreviewOpen = yield select(presentationSelectors.getIsCallPreviewOpen)

  let container
  if (isLargeVideo) container = trackService.getLargeVideoContainer()
  else if (isGridView) container = trackService.getGridVideoContainerById(userId)
  else if (isCallPreviewOpen) container = trackService.getCallPreviewVideoContainerById(userId)
  else container = trackService.getVideoContainerById(userId)

  yield put(jitsiTrackActions.detachTrack(track))

  if (container) {
    if (isCallPreviewOpen) {
      const parsedBooleanVideoMuted = JSON.parse(localStorage.getItem(VIDEO_MUTED))
      if (parsedBooleanVideoMuted) {
        yield put(muteVideo())
      }
    }

    track.attach(container)
  }

  yield put(usersActions.resetVideoLoadForUser(userId))
}

function* attachAudio(action) {
  let { userId, container, trackToAttach } = action.payload
  const tracksState = yield select((state) => state.presentation.tracks)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  let track = trackService.getTrack(tracksState, userId, currentUserId, AUDIO_DEVICE)

  if (!track) {
    return
  }
  const isCallPreviewOpen = yield select(presentationSelectors.getIsCallPreviewOpen)

  if (isCallPreviewOpen) container = trackService.getCallPreviewAudioContainerById(userId)

  if (Boolean(trackToAttach) && trackToAttach.track.id !== track.track.id) {
    yield put(removeLocalTrack(track))
    yield put(jitsiTrackActions.stopTrack(track))
    yield take(trackStopped)

    yield put(updateLocalTrack(trackToAttach))
    track = trackToAttach
  }

  if (container) {
    if (isCallPreviewOpen) {
      const parsedBooleanAudioMuted = JSON.parse(localStorage.getItem(AUDIO_MUTED))
      if (parsedBooleanAudioMuted) {
        yield put(muteAudio())
      }
    }

    track.attach(container)
    track.isLocal() ? container.pause() : container.play()
  }
}

function* enterRoom() {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  yield put(usersActions.setVideoLoadForUser(currentUserId))
}

function* onUserJoined(action) {
  const userId = parseInt(action.payload[0])
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  if (userId !== currentUserId) {
    yield put(usersActions.setVideoLoadForUser(userId))
  }
}
function* jitsiTrackSaga() {
  yield all([
    takeLatest(jitsiTrackActions.addTrackToRoom, addTrackToRoom),
    takeLatest(jitsiTrackActions.detachTrack, detachTrack),
    takeLatest(jitsiTrackActions.stopTrack, stopTrack),
    takeLatest(jitsiTrackActions.attachVideo, attachVideo),
    takeLatest(jitsiTrackActions.attachAudio, attachAudio),
    takeLatest(connectionActions.enterRoom, enterRoom),
    takeLatest(conferenceActions.onUserJoined, onUserJoined),
  ])
}

export default jitsiTrackSaga
