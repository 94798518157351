import { handleActions, combineActions } from 'redux-actions'
import { NotificationType } from '@tabeeb/enums'
import { acceptInvite } from '@tabeeb/modules/invites/actions'
import * as rawActions from '../actions'
import { signalrEvents, signalrConstants } from '../../signalr'

const defaultValue = 0

export default handleActions(
  {
    [rawActions.getNotificationsCountSuccess](state, action) {
      return action.response.data
    },
    [rawActions.resetState](state, { payload }) {
      return defaultValue
    },
    [signalrEvents[signalrConstants.tabeebHubName].onAddNotification](state, { payload }) {
      const addedNotification = payload[0]
      if (
        addedNotification?.NotificationType === NotificationType.YouNewOwner ||
        addedNotification?.NotificationType === NotificationType.YouOldOwner
      ) {
        return state
      }

      return state + 1
    },
    [combineActions(
      signalrEvents[signalrConstants.tabeebHubName].onDeleteNotification,
      signalrEvents[signalrConstants.tabeebHubName].onDeleteAdminNotification,
      rawActions.rejectNotificationSuccess,
      rawActions.acceptNotificationSuccess,
      acceptInvite.success
    )](state, action) {
      return state - 1
    },
  },
  defaultValue
)
