import { put, takeLatest, select, fork, call, take, all } from 'redux-saga/effects'

import * as navbarActions from '@tabeeb/modules/sessionTabs/actions'
import * as conferenceActions from '@tabeeb/modules/presentation/actions/conference'
import * as connectionActions from '@tabeeb/modules/presentation/actions/connection'
import * as jitsiTrackActions from '@tabeeb/modules/presentation/actions/jitsiTrack'
import * as devicesActions from '@tabeeb/modules/presentation/actions/devices'
import * as tracksActions from '@tabeeb/modules/presentation/actions/tracks'
import * as rawContentActions from '@tabeeb/shared/content/actions'
import * as contentStateSelectors from '@tabeeb/shared/content/selectors'
import { screenSharingDefaults } from '@tabeeb/modules/presentation/common/screenSharingDefaults'
import bindEvent from '@tabeeb/modules/shared/utils/bindEvent'
import { notificationActions } from '@tabeeb/modules/notification'
import { signalrActions } from '@tabeeb/modules/signalr'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as accountSelectors from '@tabeeb/modules/account/selectors'
import * as whiteboardService from '@tabeeb/modules/whiteboard/services'
import { getBrowserType } from '@tabeeb/modules/shared/browser'
import * as trackService from '@tabeeb/modules/presentation/services/trackService'
import * as connectionService from '@tabeeb/modules/presentation/services/connectionService'
import { usersActions } from '@tabeeb/modules/../users'

let externalEventChannels = []

function* startCall() {
  const currentBottomPanel = yield select((state) => state.navbar.currentPanel.bottom)
  const isFormsAndUsersTabCollapsed = yield select((state) => state.appConfigState.isFormsAndUsersTabCollapsed)

  if (isFormsAndUsersTabCollapsed && currentBottomPanel !== TabPanel.Users) {
    yield put(navbarActions.switchPanel(TabPanel.Users, TabPanelLocation.Bottom))
  }
}

function* notifyStartCall() {
  const contentId = yield select((state) => `${contentStateSelectors.getContentId(state)}`)
  const presenterId = yield select(contentStateSelectors.getPresenterId)

  yield whiteboardService.enterRoom(contentId, presenterId)
}

function* onUserReadyForCall() {
  const jitsiRoom = yield select((state) => state.presentation.conference.room)
  jitsiRoom?.join()

  const contentId = yield select((state) => `${contentStateSelectors.getContentId(state)}`)
  const presenterId = yield select(contentStateSelectors.getPresenterId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  yield put(conferenceActions.closeCallPreviewDialog())

  if (currentUserId === presenterId) {
    yield put(rawContentActions.setPresenterRequest({ contentId, presenterId }))
    yield take([rawContentActions.setPresenterSuccess])

    yield put(signalrActions.invokeHubAction({ method: 'SetPresenter', args: [contentId, presenterId] }))
  }
  yield put(signalrActions.invokeHubAction({ method: 'SubscribeToConferenceEvents', args: [contentId] }))

  const attachAudioPayload = {
    userId: currentUserId,
    container: trackService.getAudioContainerById(currentUserId),
  }
  yield put(jitsiTrackActions.attachAudio(attachAudioPayload))

  yield put(jitsiTrackActions.attachVideo(currentUserId))
}

function* disconnect() {
  yield put(connectionActions.dispose())
  yield put(connectionActions.endCall())

  const contentId = yield select(contentStateSelectors.getContentId)
  const isCurrentUserPresenter = yield select(contentStateSelectors.getIsCurrentUserPresenter)
  if (isCurrentUserPresenter) {
    yield put(rawContentActions.setPresenterRequest({ contentId, presenterId: null }))
    yield take([rawContentActions.setPresenterSuccess])

    yield put(signalrActions.invokeHubAction({ method: 'SetPresenter', args: [contentId, null] }))
    yield put(rawContentActions.setLastCallEndTime(new Date()))
  }
}

function* enterRoom(action) {
  window.config.nick = yield select(accountSelectors.getCurrentUserId)
  yield put(connectionActions.setRoomId(action.payload))
  yield put(connectionActions.initJitsi())
}

function* initJitsi() {
  const roomId = yield select((state) => state.contentState.contentId)

  const currentVideoBridge = yield select((state) => state.topNavBar.currentVideoBridge)

  window.config.nick = yield select(accountSelectors.getCurrentUserId)
  const { config } = window
  config.bosh = `${config.bosh.split('?room=')[0]}?room=${roomId}`
  config.appId = 'collaborate.center'
  config.disableBeforeUnloadHandlers = true
  window.config = { ...config, ...screenSharingDefaults }

  const isManualVideoBridgeSelection = yield select((state) => state.appConfigState.showVideobridgeRegionDropdown)
  if (isManualVideoBridgeSelection) {
    if (currentVideoBridge?.url) {
      const domain = currentVideoBridge.url
      window.config.hosts.domain = domain
      window.config.hosts.muc = `conference.${domain}`
      window.config.hosts.focus = `focus.${domain}`
      window.config.bosh = `//${domain}/http-bind?room=${roomId}`
      window.config.name = 'Tabeeb'
    }
  }
  yield call(connectionService.initJitsi)
  yield put(connectionActions.initJitsiConnection())
}

function* initJitsiConnection() {
  const actions = []
  const accessToken = yield select((state) => state.presentation.connection.accessToken)
  const jitsiConnection = connectionService.initJitsiConnection(accessToken)
  actions.push(put(connectionActions.setJitsiConnection(jitsiConnection)))
  actions.push(put(connectionActions.bindConnectionEvents()))
  jitsiConnection.connect()
  yield all(actions)
}

function* onConnectionEstablished() {
  yield put(connectionActions.unbindConnectionEvents())
  yield put(conferenceActions.initRoom())
}

function* onConnectionFailed(action) {
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  yield put(connectionActions.unbindConnectionEvents())
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to connect to call' }))
  yield put(connectionActions.dispose())
  yield put(connectionActions.endCall())
  yield put(connectionActions.setPresenter(null))
  yield put(usersActions.resetVideoLoadForUser(currentUserId))
}

function* bindConnectionEvents() {
  const jitsiConnection = yield select((state) => state.presentation.connection.connection)

  yield fork(
    bindEvent,
    jitsiConnection,
    window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
    connectionActions.onConnectionFailed,
    externalEventChannels
  )
  yield fork(
    bindEvent,
    jitsiConnection,
    window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    connectionActions.onConnectionEstablished,
    externalEventChannels
  )
}

function* unbindConnectionEvents() {
  externalEventChannels.forEach((channel) => channel.close())
  externalEventChannels = []
}

function* dispose() {
  yield put(conferenceActions.leaveRoom())
  yield put(tracksActions.stopTracks())

  const connection = yield select((state) => state.presentation.connection.connection)
  if (connection) {
    connection.disconnect()
  }
  yield put(conferenceActions.resetFlags())
  yield put(tracksActions.disposeTracks())
  yield put(connectionActions.resetJitsiConnection())
  yield put(devicesActions.resetDevices())
}

function* connectionSaga() {
  yield all([
    takeLatest(connectionActions.startCall, startCall),
    takeLatest(connectionActions.notifyStartCall, notifyStartCall),
    takeLatest(connectionActions.onUserReadyForCall, onUserReadyForCall),
    takeLatest(connectionActions.disconnect, disconnect),
    takeLatest(connectionActions.enterRoom, enterRoom),
    takeLatest(connectionActions.initJitsi, initJitsi),
    takeLatest(connectionActions.initJitsiConnection, initJitsiConnection),
    takeLatest(connectionActions.onConnectionEstablished, onConnectionEstablished),
    takeLatest(connectionActions.onConnectionFailed, onConnectionFailed),
    takeLatest(connectionActions.bindConnectionEvents, bindConnectionEvents),
    takeLatest(connectionActions.unbindConnectionEvents, unbindConnectionEvents),
    takeLatest(connectionActions.dispose, dispose),
  ])
}

export default connectionSaga
