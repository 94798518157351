// @ts-check
import { createAction } from 'redux-actions'
import { nanoid } from 'nanoid/non-secure'

import { ToastProps } from '@tabeeb/uikit'

type Payload = {
  message: ToastProps['message']
  title?: ToastProps['title']
  options?: {
    key?: ToastProps['id']
    variant?: ToastProps['variant']
    title?: ToastProps['title']
    autoHideDuration?: ToastProps['autoHideDuration']
  }
}

type Notification = {
  key: ToastProps['id']
  message: Payload['message']
  options?: Payload['options']
  dismissed?: boolean
}

const transformPayload = (variant: ToastProps['variant'], payload: Payload): Notification => {
  const key = (payload.options && payload.options.key) || nanoid()
  return {
    key,
    message: payload.message,
    options: {
      key,
      variant,
      title: payload.title,
      ...payload.options,
    },
  }
}

export const onAddSuccessNotification = createAction<Notification, Payload>('ON_ADD_SUCCESS_NOTIFICATION', (payload) =>
  transformPayload('success', payload)
)

export const onAddErrorNotification = createAction<Notification, Payload>('ON_ADD_ERROR_NOTIFICATION', (payload) =>
  transformPayload('error', payload)
)

export const onAddWarningNotification = createAction<Notification, Payload>('ON_ADD_WARNING_NOTIFICATION', (payload) =>
  transformPayload('warning', payload)
)

export const onAddInfoNotification = createAction<Notification, Payload>('ON_ADD_INFO_NOTIFICATION', (payload) =>
  transformPayload('info', payload)
)

export const onDismissNotification = createAction<{ key: ToastProps['id']; dismissAll: boolean }, ToastProps['id']>(
  'ON_DISMISS_NOTIFICATION',
  (key) => ({ key, dismissAll: !key })
)

export const onRemoveNotification = createAction<{ key: ToastProps['id'] }, ToastProps['id']>(
  'ON_REMOVE_NOTIFICATION',
  (key) => ({ key })
)
