import { combineReducers } from 'redux'

import selectedFilters from './selectedFilters'
import notificationsList from './notificationsList'
import isDataLoading from './isDataLoading'
import batchesLoadedCount from './batchesLoadedCount'
import hasMoreNotifications from './hasMoreNotifications'
import notificationsCount from './notificationsCount'
import tabOpen from './tabOpen'

export default combineReducers({
  selectedFilters,
  notificationsList,
  isDataLoading,
  batchesLoadedCount,
  hasMoreNotifications,
  notificationsCount,
  tabOpen,
})
