import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { DialogContent, DialogTitle } from '@mui/material'
import { maxLength, noWhitespaces, requiredField } from '@tabeeb/modules/shared/utils/validationErrorMessages'
import { AIObjectPropertyType } from '@tabeeb/enums'
import { ResizableDialog } from '@tabeeb/uikit'
import * as aiActions from '../../actions'
import { getSelectedUniqueAIObject, getSelectedUniqueAIObjectsAIObject } from '../../selectors'

import AutofillButton from './AutofillButton'
import TextFieldComponent from '../TextFieldComponent'
import UniqueAIObjectPropertiesField from '../UniqueAIObjectPropertiesField'
import { validAIObjectPropertySchema } from '../../services'
import DialogActionsWithFormik from '../DialogActionsWithFormik'

const validationSchemaSchema = Yup.object().shape({
  name: Yup.string().strict().trim(noWhitespaces).max(500, maxLength(500)).required(requiredField),
  properties: Yup.array().of(
    Yup.object()
      .shape({
        value: validAIObjectPropertySchema,
      })
      .required()
  ),
})

const EditUniqueAIObjectDialog = () => {
  const dispatch = useDispatch()
  const selectedUniqueAIObject = useSelector(getSelectedUniqueAIObject)
  const aiObject = useSelector(getSelectedUniqueAIObjectsAIObject)

  const handleSubmit = (values, actions) => {
    const model = {
      Id: selectedUniqueAIObject.Id,
      Name: values.name,
      Properties: values.properties
        .filter(
          (property) =>
            (property.type !== AIObjectPropertyType.Dropdown &&
              property.value !== null &&
              property.value !== undefined &&
              property.value !== '') ||
            (property.type === AIObjectPropertyType.Dropdown && property?.value?.Value)
        )
        .map((property) => ({
          Id: property.id,
          Type: property.type,
          AIObjectPropertyId: property.aiObjectPropertyId,
          Value: (() => {
            if (property.type === AIObjectPropertyType.Dropdown) {
              return [
                {
                  Value: property.value.Value,
                  SLIId: property.value.Id,
                },
              ]
            }
            return property.value
          })(),
          Ordinal: property.ordinal,
        })),
    }

    dispatch(aiActions.updateUniqueAiObjectRequest(model))
    dispatch(aiActions.setUniqueAiObjectForEdit(null))
    dispatch(aiActions.setUniqueAiObjectAnnotationForEdit(null))
  }

  const handleClose = (reason, handleReset) => {
    if (reason && reason === 'backdropClick') {
      return
    }

    dispatch(aiActions.setUniqueAiObjectForEdit(null))
    dispatch(aiActions.setUniqueAiObjectAnnotationForEdit(null))
    handleReset()
  }

  const selectedUniqueAIObjectPropertiesMap = useMemo(() => {
    if (!selectedUniqueAIObject) {
      return {}
    }

    return Object.fromEntries(
      selectedUniqueAIObject.Properties.map((property) => [property.AIObjectPropertyId, property])
    )
  }, [selectedUniqueAIObject])

  const initialValues = useMemo(
    () => ({
      name: selectedUniqueAIObject?.Name || '',
      properties: aiObject?.Properties
        ? aiObject.Properties.map((aiObjectProperty) => ({
            id: selectedUniqueAIObjectPropertiesMap[aiObjectProperty.Id]?.Id || undefined,
            aiObjectPropertyId: aiObjectProperty.Id,
            name: aiObjectProperty.Name,
            type: aiObjectProperty.Type,
            isDisabled: aiObjectProperty.IsDisabled,
            isEditable: aiObjectProperty.IsEditable,
            isHidden: aiObjectProperty.IsHidden,
            value:
              selectedUniqueAIObjectPropertiesMap[aiObjectProperty.Id]?.Value === null ||
              aiObjectProperty.Type !== AIObjectPropertyType.Dropdown
                ? selectedUniqueAIObjectPropertiesMap[aiObjectProperty.Id]?.Value
                : {
                    Id: selectedUniqueAIObjectPropertiesMap[aiObjectProperty.Id]?.Value[0].SLIId,
                    Value: selectedUniqueAIObjectPropertiesMap[aiObjectProperty.Id]?.Value[0].Value,
                  },
            ordinal: aiObjectProperty.Ordinal,
            autofillSource: aiObjectProperty.AutofillSource,
            selectionListId: aiObjectProperty.SelectionListId,
          }))
        : [],
    }),
    [aiObject.Properties, selectedUniqueAIObject?.Name, selectedUniqueAIObjectPropertiesMap]
  )

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaSchema}
    >
      {({ values, errors, handleReset }) => (
        <ResizableDialog
          allowBackgroundInteraction
          open={Boolean(selectedUniqueAIObject)}
          onClose={(event, reason) => handleClose(reason, handleReset)}
          header={<DialogTitle sx={{ flexGrow: 1, pb: 0 }}>Edit properties</DialogTitle>}
          content={
            <>
              <AutofillButton uniqueAIObject={selectedUniqueAIObject} />
              <DialogContent>
                <Form>
                  <Field
                    disabled
                    name='name'
                    placeholder='Name'
                    label='Name'
                    variant='outlined'
                    sx={{ marginBottom: 1 }}
                    component={TextFieldComponent}
                  />
                  <Field name='properties' component={UniqueAIObjectPropertiesField} />
                </Form>
              </DialogContent>
              <DialogActionsWithFormik />
            </>
          }
        />
      )}
    </Formik>
  )
}

export default EditUniqueAIObjectDialog
