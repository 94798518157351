import { select, all, take, takeEvery, put } from 'redux-saga/effects'

import * as signalrActions from '../actions'

import { getSignalRConnection, getSignalRConnectionState } from '../selectors'
import { tabeebHubName } from '../services/constants'
import { signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { getIsCallStarted } from '@tabeeb/modules/presentation/selectors'

function* invokeHubAction(action) {
  const { method, args = [], hubName = tabeebHubName } = action.payload

  const isEstablished = yield select((state) => getSignalRConnectionState(state, hubName))
  if (!isEstablished) {
    while (true) {
      const successAction = yield take(signalrActions.createConnectionSuccess)
      if (successAction.payload.hubName === hubName) {
        break
      }
    }
  }
  const connection = yield select((state) => getSignalRConnection(state, hubName))
  connection.invoke(method, ...args).catch((e) => {})
}

function* onConnected() {
  const roomId = yield select((state) => state.presentation.connection.roomId)
  const callStarted = yield select(getIsCallStarted)

  if (roomId > 0 && callStarted) {
    yield put(signalrActions.invokeHubAction({ method: 'SubscribeToConferenceEvents', args: [roomId] }))
  }
}

export default function* hubCalls() {
  yield all([
    takeEvery(signalrActions.invokeHubAction, invokeHubAction),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onConnected, onConnected),
  ])
}
