import { handleActions } from 'redux-actions'

import { closeNotificationsTab, openNotificationsTab } from '../actions'

const defaultState = false

export default handleActions(
  {
    [openNotificationsTab]() {
      return true
    },
    [closeNotificationsTab]() {
      return defaultState
    },
  },
  defaultState
)
