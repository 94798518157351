import React from 'react'

import { FieldProps } from 'formik'
import { TextField as UIKitTextField, TextFieldProps as UIKitTextFieldProps } from '@tabeeb/uikit'

export type TextFieldProps = FieldProps &
  UIKitTextFieldProps & {
    value: string | number
  } & {
    max?: number
    min?: number
    step?: number
  }

const TextField: React.FC<TextFieldProps> = ({ value, fullWidth = true, ...props }) => {
  const validateInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props.type === 'number') {
      const charCode = e.which ? e.which : e.keyCode
      const restrictedChars = [107, 109, 187, 189, 69]

      if (restrictedChars.some((char) => char === charCode)) {
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      return true
    }

    return true
  }

  return (
    <UIKitTextField
      color='primary'
      size='small'
      title={`${value}`}
      fullWidth={fullWidth}
      InputProps={{
        inputProps: {
          max: props.max,
          min: props.min,
          step: props.step || 1,
          onKeyDown: validateInput,
        },
      }}
      {...props}
      value={value || value === 0 ? value : ''}
    />
  )
}

export default TextField
