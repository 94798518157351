import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import { CallEnd, VideoCall, Videocam } from '@mui/icons-material'

import { getHasOngoingCall, getIsCurrentUserPresenter } from '@tabeeb/modules/shared/content/selectors'

import { leaveVideoCall } from '@tabeeb/modules/shared/content/actions'
import { notifyStartCall } from '@tabeeb/modules/presentation/actions/connection'

import {
  getIsCallInitializationInProgress,
  getIsCallLeavingInProgress,
  getIsInPresentationMode,
} from '@tabeeb/modules/presentation/selectors'
import { getIsVideoCallsEnabled } from '@tabeeb/modules/appConfigState/selectors'

import { getHasStartCallPermission } from '../../selectors'

const StartCallButton = () => {
  const dispatch = useDispatch()

  const isPresenter = useSelector(getIsCurrentUserPresenter)
  const isCallStarted = useSelector(getIsInPresentationMode)
  const isCallInitializationInProgress = useSelector(getIsCallInitializationInProgress)
  const isCallLeavingInProgress = useSelector(getIsCallLeavingInProgress)

  const hasStartCallPermission = useSelector(getHasStartCallPermission)
  const isVideoCallsFeatureEnabled = useSelector(getIsVideoCallsEnabled)

  const hasOngoingCall = useSelector(getHasOngoingCall)

  const onJoinCall = useCallback(() => {
    dispatch(notifyStartCall())
  }, [dispatch])

  const onLeaveCall = useCallback(() => {
    dispatch(leaveVideoCall({ force: false }))
  }, [dispatch])

  const buttonProps = useMemo(() => {
    if (isCallStarted) {
      return {
        color: 'error',
        disabled: isCallLeavingInProgress,
        startIcon: <CallEnd />,
        title: isPresenter ? 'End call' : 'Leave call',
        onClick: onLeaveCall,
      }
    }

    if (!hasOngoingCall && !hasStartCallPermission) {
      return null
    }

    return {
      color: 'primary',
      disabled: isCallInitializationInProgress,
      startIcon: hasOngoingCall ? <VideoCall /> : <Videocam />,
      title: hasOngoingCall ? 'Join' : 'Start call',
      onClick: onJoinCall,
    }
  }, [
    hasOngoingCall,
    hasStartCallPermission,
    isCallInitializationInProgress,
    isCallLeavingInProgress,
    isCallStarted,
    isPresenter,
    onJoinCall,
    onLeaveCall,
  ])

  if (!buttonProps || !isVideoCallsFeatureEnabled) {
    return null
  }

  return (
    <Button variant='contained' {...buttonProps}>
      {buttonProps.title}
    </Button>
  )
}

StartCallButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleCallButton: PropTypes.func.isRequired,
  handleLeaveButton: PropTypes.func.isRequired,
  isStartCallBtn: PropTypes.bool.isRequired,
  isEndCallBtn: PropTypes.bool.isRequired,
  isLeaveCallBtn: PropTypes.bool.isRequired,
  showCallBtn: PropTypes.bool.isRequired,
}

export default StartCallButton
