import { put, takeLatest, select, all } from 'redux-saga/effects'

import { getSelectedGalleryItemId } from '@tabeeb/modules/forms/selectors'
import { getIsCurrentUserPresenter, getContentId } from '@tabeeb/shared/content/selectors'
import { getIsCallStarted } from '@tabeeb/modules/presentation/selectors'
import * as contentTrashBinActions from '@tabeeb/modules/contentTrashBin/actions'
import * as rawActions from '../actions'
import { signalrEvents, signalrConstants, signalrActions } from '../../signalr'
import { getFolderById } from '../selectors'

import { notificationActions } from '../../notification'

import { ErrorCode } from '../../../Enums'
import * as usersSelectors from '../../../users/selectors'
import { PDF_DOCUMENT_SECOND_PAGE_NUMBER } from '../services/constants'

function* createSessionFolderSuccess(action) {
  const { data } = action.response

  yield put(rawActions.addSessionFolder(data))
}

function* deleteSessionFolder(action) {
  const { folderId } = action.payload

  const { selectedFolderId, selectedGalleryItemId } = yield select((state) => state.gallery.galleryState)
  const galleryList = yield select((state) => state.gallery.galleryList)

  const pagesFromDeletedFolder = galleryList.filter(
    (item) => item.folderId === folderId && item.pdfPageNumber < PDF_DOCUMENT_SECOND_PAGE_NUMBER
  )

  if (selectedFolderId === folderId) {
    yield put(rawActions.selectSessionFolder({ folderId: null }))

    const selectedPage = galleryList.find((item) => item.id === selectedGalleryItemId)
    if (!selectedPage || selectedPage.folderId === folderId) {
      const nextItem = galleryList.filter((page) => page.folderId === null)[0]
      yield put(rawActions.onSelectGalleryItem({ pageId: nextItem.id }))
    }
  }

  if (pagesFromDeletedFolder.length > 0) {
    for (let i = 0; i < pagesFromDeletedFolder.length - 1; i++) {
      yield put(rawActions.deleteGalleryItem({ pageId: pagesFromDeletedFolder[i].id }))
    }
    const lastPageFromDeletedFolder = pagesFromDeletedFolder[pagesFromDeletedFolder.length - 1]
    yield put(rawActions.onDeleteGalleryItem({ pageId: lastPageFromDeletedFolder.id }))
  }

  yield put(rawActions.removeSessionFolder(folderId))
}

function* updatePageSessionFolderSuccess(action) {
  const { pageIds, folderId } = action.payload

  const galleryList = yield select((state) => state.gallery.galleryList)
  const { selectedFolderId, selectedGalleryItemId } = yield select((state) => state.gallery.galleryState)

  for (let i = 0; i < pageIds.length; i++) {
    const pageId = pageIds[i]
    const galleryItem = galleryList.find((item) => item.id === pageId)

    if (galleryItem) {
      const page = {
        ...galleryItem,
        folderId,
      }

      yield put(rawActions.updateGalleryItem(page))

      if (folderId !== selectedFolderId && page.id === selectedGalleryItemId) {
        yield put(rawActions.selectSessionFolder({ folderId, selectFirstItem: false }))
      }
    }
  }

  yield put(rawActions.clearSelection())
}

function* renameSessionFolderSuccess(action) {
  const { Id, Name } = action.payload

  const folder = yield select((state) => getFolderById(state, { id: Id }))
  if (folder) {
    yield put(rawActions.updateSessionFolder({ ...folder, Name }))
  }
}

function* renameSessionFolderFailed(action) {
  if (action.response.data && action.response.data.Error.Code == ErrorCode.ModelInvalid) {
    yield put(
      notificationActions.onAddErrorNotification({ message: 'Folder name cannot be empty or exceed 400 characters.' })
    )
  } else {
    yield put(notificationActions.onAddErrorNotification({ message: 'Failed to delete folder.' }))
  }
}

function* selectSessionFolder(action) {
  const { folderId, selectFirstItem = true } = action.payload

  yield put(rawActions.clearSelection())

  const { selectedFolderId } = yield select((state) => state.gallery.galleryState)
  const { galleryList, foldersList } = yield select((state) => state.gallery)
  const folder = foldersList.find((item) => item.Id === selectedFolderId)

  if (!folder && selectedFolderId !== null) return

  if (selectFirstItem) {
    const currentFolderPages = galleryList.filter((item) => item.folderId === folderId)
    if (currentFolderPages.length > 0) {
      yield put(rawActions.onSelectGalleryItem({ pageId: currentFolderPages[0].id }))
    }
  }
}

function* onSessionFolderAdded(action) {
  const isReviewer = yield select((state) => usersSelectors.getIsCurrentUserReviewer(state))
  if (isReviewer) return

  const [model] = action.payload

  yield put(rawActions.addSessionFolder(model))
}

function* onSessionFolderDeleted(action) {
  const [folderId] = action.payload

  yield put(rawActions.onDeleteSessionFolder({ folderId }))
  const sessionId = yield select(getContentId)
  yield put(contentTrashBinActions.getDeletedSessionFoldersRequest(sessionId))
  yield put(contentTrashBinActions.getDeletedPagesRequest(sessionId))
}

function* onSessionFolderUpdated(action) {
  const [model] = action.payload

  yield put(rawActions.updateSessionFolder(model))
}

function* onPageSessionFolderUpdated(action) {
  const [{ PageIds, FolderId }] = action.payload
  const isCallStarted = yield select(getIsCallStarted)
  const currentSelectedGalleryItemId = yield select(getSelectedGalleryItemId)
  const isCurrentUserPresenter = yield select(getIsCurrentUserPresenter)
  const contentId = yield select(getContentId)

  const galleryList = yield select((state) => state.gallery.galleryList)
  for (let i = 0; i < PageIds.length; i++) {
    const pageId = PageIds[i]

    const page = galleryList.find((p) => p.id === pageId)
    if (page) {
      const updatedPage = { ...page, folderId: FolderId }

      yield put(rawActions.updateGalleryItem(updatedPage))
      if (page.id === currentSelectedGalleryItemId && isCallStarted && isCurrentUserPresenter) {
        // moved page is currently selected by presenter in the call. Need to update presenter selected folder as well
        yield put(rawActions.selectSessionFolder({ folderId: FolderId, selectFirstItem: false }))
        const message = {
          pageId,
          timeStamp: Date.now(),
          type: 'setPageById',
          handleObj: {
            guid: Date.now(),
            type: 'setPageById',
            origType: 'setPageById',
          },
        }

        yield put(signalrActions.invokeHubAction({ method: 'PresenterAction', args: [contentId, message] }))
      }
    }
  }
}

function* createSessionFolderFailed(action) {
  const {
    response: {
      data: { Error },
    },
  } = action

  if (Error.Code == ErrorCode.ModelInvalid) {
    yield put(
      notificationActions.onAddErrorNotification({ message: 'Folder name cannot be empty or exceed 400 characters.' })
    )
  } else {
    yield put(notificationActions.onAddErrorNotification({ message: 'Failed to create folder.' }))
  }
}

function* addImages() {
  yield all([
    takeLatest(rawActions.selectSessionFolder, selectSessionFolder),
    takeLatest(rawActions.createSessionFolderSuccess, createSessionFolderSuccess),
    takeLatest(rawActions.createSessionFolderFailed, createSessionFolderFailed),
    takeLatest(rawActions.onDeleteSessionFolder, deleteSessionFolder),
    takeLatest(rawActions.renameSessionFolderSuccess, renameSessionFolderSuccess),
    takeLatest(rawActions.renameSessionFolderFailed, renameSessionFolderFailed),
    takeLatest(rawActions.updatePageSessionFolderSuccess, updatePageSessionFolderSuccess),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onSessionFolderAdded, onSessionFolderAdded),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onSessionFolderDeleted, onSessionFolderDeleted),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onSessionFolderUpdated, onSessionFolderUpdated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onPageSessionFolderUpdated, onPageSessionFolderUpdated),
  ])
}

export default addImages
