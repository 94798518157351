import PropTypes from 'prop-types'
import { FormControl, FormHelperText, FormControlLabel, Checkbox } from '@mui/material'

const FormikCheckboxMui5 = ({ field, form, label, labelProps = {}, fullWidth = false, sx = {}, ...props }) => {
  const { errors, touched } = form
  const error = touched[field.name] && errors[field.name]

  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.checked, /* shouldValidate */ true)
  }

  return (
    <FormControl error={!!error} fullWidth={fullWidth} sx={sx}>
      <FormControlLabel
        {...labelProps}
        control={<Checkbox {...field} {...props} checked={field.value || false} onChange={handleChange} />}
        label={label}
      />
      {error && <FormHelperText sx={{ marginTop: '-4px' }}>{error}</FormHelperText>}
    </FormControl>
  )
}

FormikCheckboxMui5.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.node,
  labelProps: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  sx: PropTypes.shape({}),
}

export default FormikCheckboxMui5
