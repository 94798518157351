export function mapBillingSettingsValues(values) {
  const billingSettings = {}

  billingSettings.IsBillingEnabled = values.IsBillingEnabled
  billingSettings.ExpirationInDays = values.RecurringPayment ? values.ExpirationInDays : null
  billingSettings.SessionPrice = values.SessionPrice

  return billingSettings
}

export function mapSessionApprovalSettingsValues(values) {
  const sessionApprovalSettings = {}

  sessionApprovalSettings.UserSessionCreationLimit = values.UserSessionCreationLimitUnlimited
    ? null
    : values.UserSessionCreationLimit
  sessionApprovalSettings.SingleSessionActivationsLimit = values.SingleSessionActivationsLimitUnlimited
    ? null
    : values.SingleSessionActivationsLimit
  sessionApprovalSettings.UserSessionCreationLimitUnlimited = values.UserSessionCreationLimitUnlimited
  sessionApprovalSettings.SingleSessionActivationsLimitUnlimited = values.SingleSessionActivationsLimitUnlimited
  sessionApprovalSettings.ContentCreationRestricted = values.ContentCreationRestricted

  return sessionApprovalSettings
}
