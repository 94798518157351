import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { CloudUpload, Refresh, Tune } from '@mui/icons-material'
import { Badge, FormControl, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import { getIsContentMetadataEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { Span } from '@tabeeb/uikit/components'

import { getAIReport, getAIReportAiObjects, getSelectedAiObjectId } from '../../selectors'
import { openAiReportPublishSettingsDialog } from '../../actions'
import { ButtonContentContainer, ButtonOutlined, HeaderContainer } from './styles'

const AIReportDialogHeader = ({ isInEditMode, getAiObjectAnalysisDebounced }) => {
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')
  const [searchFields, setSearchFields] = useState([])

  const isSpreadsheetFeatureEnabled = useSelector(getIsContentMetadataEnabled)
  const aiObjects = useSelector(getAIReportAiObjects)
  const selectedAiObjectId = useSelector(getSelectedAiObjectId)
  const report = useSelector(getAIReport)

  const selectedAiObjectName = aiObjects.find((o) => o.Id === selectedAiObjectId)?.Name

  useEffect(() => {
    getAiObjectAnalysisDebounced({ search, searchFields })
  }, [getAiObjectAnalysisDebounced, search, searchFields])

  return (
    <HeaderContainer>
      {isInEditMode ? (
        <Span style={{ fontWeight: 600, fontSize: 16 }}>
          Editing - &apos;{selectedAiObjectName}&apos; object templates
        </Span>
      ) : (
        <div>
          {isSpreadsheetFeatureEnabled && (
            <ButtonOutlined
              onClick={() => {
                dispatch(openAiReportPublishSettingsDialog())
              }}
              variant='contained'
            >
              <ButtonContentContainer>
                <CloudUpload />
                Publish
              </ButtonContentContainer>
            </ButtonOutlined>
          )}

          <ButtonOutlined
            sx={{ minWidth: '40px !important', width: '40px !important' }}
            onClick={getAiObjectAnalysisDebounced}
            variant='contained'
          >
            <ButtonContentContainer>
              <Refresh />
            </ButtonContentContainer>
          </ButtonOutlined>
          <TextField
            style={{ width: 230, margin: 5 }}
            name='search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            label='Search'
            variant='outlined'
            size='small'
            InputProps={{
              endAdornment: (
                <Badge badgeContent={searchFields.length} color='primary'>
                  <FormControl>
                    <Select
                      multiple
                      disableUnderline
                      value={searchFields}
                      onChange={(e) => setSearchFields(e.target.value)}
                      IconComponent={Tune}
                      renderValue={() => null}
                      SelectDisplayProps={{ style: { minWidth: 0 } }}
                    >
                      {report?.columns.map((c) => (
                        <MenuItem key={c.Name} value={c.Name}>
                          {c.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Badge>
              ),
            }}
          />
        </div>
      )}
    </HeaderContainer>
  )
}

AIReportDialogHeader.propTypes = {
  isInEditMode: PropTypes.bool.isRequired,
  getAiObjectAnalysisDebounced: PropTypes.func.isRequired,
}

export default AIReportDialogHeader
