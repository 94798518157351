import { put, takeLatest, all, take, select } from 'redux-saga/effects'

import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import * as alterOwnerActions from '@tabeeb/modules/alertOwner/actions'
import { notificationActions } from '@tabeeb/modules/notification'
import { NotificationType, ContentSharingType, ApprovalRequestType, SessionNamingConventionType } from '@tabeeb/enums'
import { copyContent } from '@tabeeb/modules/contentSharings/actions'

import { accountActions } from '@tabeeb/modules/account'
import { goToContent } from '@tabeeb/modules/shared/content/actions'
import { remindersActions } from '@tabeeb/modules/reminders'
import { reminderNotifications } from '@tabeeb/modules/reminders/services/reminderNotifications'
import { tenantFormsSelectors } from '@tabeeb/modules/tenantForms'
import * as rawActions from '../actions'
import * as notificationsPageSelectors from '../selectors'
import * as tenantFormActions from '../../tenantForms/actions'
import { NOTIFICATIONS_BATCH_SIZE } from '../constants'
import { convertNotification } from '../services/notificationConverter'
import { closeNotificationsTab } from '../actions'

function* loadNotifications() {
  const batchesLoadedCount = yield select(notificationsPageSelectors.getBatchedLoadedCount)
  yield put(
    rawActions.getNotificationsRequest({
      batchNumber: batchesLoadedCount + 1,
      batchSize: NOTIFICATIONS_BATCH_SIZE,
      showAll: 0,
    })
  )
}

function* setNotifications(action) {
  const notifications = action.response.data
  const convertedNotifications = notifications.map((notification) => {
    notification = convertNotification(notification)
    return notification
  })
  yield put(rawActions.setNotificationsList(convertedNotifications))
  yield put(rawActions.setNotificationsListLoading(false))

  const batchesLoadedCount = yield select(notificationsPageSelectors.getBatchedLoadedCount)
  yield put(rawActions.setBatchesCount(batchesLoadedCount + 1))

  const hasMore = notifications.length === NOTIFICATIONS_BATCH_SIZE
  yield put(rawActions.setShouldLoadMore(hasMore))
}

function* acceptNotification(action) {
  const notification = action.payload
  const { useSessionNameConvention, openNamingFormForCopiedSessions } = yield select((state) => state.appConfigState)

  if (
    useSessionNameConvention &&
    openNamingFormForCopiedSessions &&
    notification.Type === ApprovalRequestType.ShareSession &&
    notification.ContentSharingId > 0 &&
    notification.ContentSharingType === ContentSharingType.Shared
  ) {
    notification.IsCopyFormAnswers = useSessionNameConvention && openNamingFormForCopiedSessions
    yield put(tenantFormActions.openTenantFormsDialog({ sncType: SessionNamingConventionType.CopySession }))
    yield take([tenantFormActions.submitTenantFormAnswers, tenantFormActions.closeTenantFormsDialog])
    const submittedAnswers = yield select(tenantFormsSelectors.getSubmittedAnswers)

    if (submittedAnswers?.length > 0) {
      notification.SessionName = submittedAnswers
      notification.ContentNameParts = yield select(tenantFormsSelectors.getContentNameParts)
      yield put(tenantFormActions.closeTenantFormsDialog())
    } else {
      notification.IsCopyFormAnswers = false
    }
  }

  yield put(rawActions.acceptNotificationRequest(notification))
}

function* acceptNotificationSuccess(action) {
  const notification = action.payload

  if (
    notification.ContentSharingId > 0 &&
    (notification.ContentSharingType === ContentSharingType.Shared ||
      notification.ContentSharingType === ContentSharingType.ContentsMerging ||
      notification.ContentSharingType === ContentSharingType.MergeForReview)
  ) {
    notification.IsProcessing = true
    notification.NeedConfirmation = false

    yield put(rawActions.replaceNotification(notification))
    yield put(
      copyContent.request({
        contentSharingId: notification.ContentSharingId,
        contentName: notification.SessionName,
        contentNameParts: notification.ContentNameParts,
        isCopyFormAnswers: notification.IsCopyFormAnswers,
      })
    )
  } else {
    yield put(rawActions.removeNotification(notification))
  }

  const regex = RegExp(/whiteboard\/plugin\?sessionId/)
  const contentPageIsOpen = regex.test(window.location.toString())

  if (
    contentPageIsOpen &&
    (notification.NotificationType === NotificationType.AlertOwner ||
      reminderNotifications.includes(notification.NotificationType))
  ) {
    const { origin, pathname } = window.location
    const link = `${origin}${pathname}/whiteboard/plugin?sessionId=${notification.ContentId}`

    window.open(link, '_blank')
  }

  if (
    notification.InvitationId > 0 ||
    ((notification.NotificationType === NotificationType.AlertOwner ||
      notification.NotificationType === NotificationType.InContentReview) &&
      !contentPageIsOpen) ||
    (notification.ContentSharingId > 0 && notification.ContentSharingType === ContentSharingType.ForReview) ||
    (reminderNotifications.includes(notification.NotificationType) && !contentPageIsOpen)
  ) {
    yield put(closeNotificationsTab())
    yield put(goToContent({ id: notification.ContentId }))
  }
}

function* revokeSessionSharing(action) {
  const notification = action.payload
  const message = `${notification.SessionName} has been revoked successfully.`
  yield put(notificationActions.onAddInfoNotification({ message }))
}

function* addNewNotification(action) {
  let notification = action.payload
  notification = convertNotification(notification)
  yield put(rawActions.addNotification(notification))
}

function* deleteNotification(action) {
  let notification = action.payload
  notification = convertNotification(notification)
  yield put(rawActions.removeNotification(notification))
}

function* updateNotification(action) {
  let notification = action.payload
  notification = convertNotification(notification)
  yield put(rawActions.replaceNotification(notification))
}

function* markNotificationsAsViewed() {
  const notificationsList = yield select(notificationsPageSelectors.getNotificationsList)

  const notificationsToMark = notificationsList
    .filter(
      (n) => !n.NeedConfirmation && n.HasBeenViewed && !n.CanBeRevoked && n.Type !== ApprovalRequestType.CalendarEvent
    )
    .map((notification) => ({
      NotificationId: notification.NotificationId,
      ContentSharingId: notification.ContentSharingId,
      BillingActivityId: notification.BillingActivityId,
    }))
  if (notificationsToMark.length > 0) {
    yield put(rawActions.markNotificationsAsViewedRequest(notificationsToMark))
  }

  for (const notification of notificationsToMark) {
    yield put(rawActions.removeNotification(notification))
  }
}

function* onAddNotification(action) {
  const [notification] = action.payload
  if (notification) {
    yield put(rawActions.addNewNotification(notification))
  }

  if (notification?.NotificationType === NotificationType.AlertOwner) {
    yield put(alterOwnerActions.openAlertOwnerModal(notification))
  }

  if (reminderNotifications.includes(notification?.NotificationType)) {
    yield put(remindersActions.addReminderNotification(notification))
  }
}

function* onDeleteNotification(action) {
  const [notification] = action.payload
  if (notification) {
    yield put(rawActions.deleteNotification(notification))
  }
}

function* onUpdateNotification(action) {
  const [notification] = action.payload
  yield put(rawActions.updateNotification(notification))
}

function* onContentCopied(action) {
  const [model] = action.payload

  let message

  switch (model.Type) {
    case ContentSharingType.ContentsMerging:
      message = `Session '${model.SessionName}' has been shared to destination successfully.`
      break
    case ContentSharingType.MergeForReview:
    case ContentSharingType.MergeReviewedData:
      message = `Session '${model.SessionName}' has been exported successfully.`
      break
    default:
      message = `Session '${model.SessionName}' has been copied successfully.`
  }

  yield put(notificationActions.onAddInfoNotification({ message }))
}

function* resetState(action) {
  yield put(rawActions.resetState())
}

function* notificationsPageSaga() {
  yield all([
    takeLatest(rawActions.loadNotifications, loadNotifications),
    takeLatest(rawActions.getNotificationsSuccess, setNotifications),
    takeLatest(rawActions.acceptNotification, acceptNotification),
    takeLatest(rawActions.acceptNotificationSuccess, acceptNotificationSuccess),
    takeLatest(rawActions.revokeSessionSharingSuccess, revokeSessionSharing),
    takeLatest(rawActions.addNewNotification, addNewNotification),
    takeLatest(rawActions.deleteNotification, deleteNotification),
    takeLatest(rawActions.updateNotification, updateNotification),
    takeLatest(rawActions.markNotificationsAsViewed, markNotificationsAsViewed),
    takeLatest(accountActions.onLogout, resetState),
    // signalr
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onAddNotification, onAddNotification),
    takeLatest(
      [
        signalrEvents[signalrConstants.tabeebHubName].onDeleteNotification,
        signalrEvents[signalrConstants.tabeebHubName].onContentCopied,
        signalrEvents[signalrConstants.tabeebHubName].onDeleteAdminNotification,
      ],
      onDeleteNotification
    ),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onContentCopied, onContentCopied),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onUpdateNotification, onUpdateNotification),
  ])
}

export default notificationsPageSaga
