import PropTypes from 'prop-types'
import { Badge, Box, IconButton, ListItem, ListSubheader } from '@mui/material'
import { FilterList } from '@mui/icons-material'

import { Span } from '@tabeeb/uikit/components'
import DragNDropList from '@tabeeb/uikit/components/DragNDropList'
import getColumnBadgeNumber from '../../helpers/getColumnBadgeNumber'

import { ListHeader, StyledList, StyledListItem } from './styles'

const ColumnList = ({ values, secondaryAction, droppableId, header }) => {
  return (
    <Box display='flex' flexDirection='column' flex={1} maxHeight='50%' overflow='auto'>
      <ListSubheader sx={{ background: '#fff', pl: 4, height: 56, lineHeight: '56px' }}>
        <ListHeader>{header}</ListHeader>
      </ListSubheader>

      <DragNDropList
        values={values}
        droppableId={droppableId}
        valueIdGetter={(column) => column.field}
        noItemsLabel={
          <ListItem>
            <Span>No columns</Span>
          </ListItem>
        }
        ListComponent={StyledList}
        renderValue={(column, draggableProvided, draggableSnapshot) => {
          return (
            <StyledListItem key={column.field}>
              <Span title={column.field}>{column.field}</Span>

              {secondaryAction && (
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  overlap='rectangular'
                  color='primary'
                  badgeContent={getColumnBadgeNumber(column)}
                >
                  <IconButton onClick={(e) => secondaryAction(e, column)} size='small' edge='end'>
                    <FilterList />
                  </IconButton>
                </Badge>
              )}
            </StyledListItem>
          )
        }}
      />
    </Box>
  )
}

ColumnList.propTypes = {
  droppableId: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  secondaryAction: PropTypes.func,
}

export default ColumnList
