import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToasts } from '@tabeeb/uikit'
import { onRemoveNotification } from '@tabeeb/modules/notification/actions'

let displayed = []

const useNotifier = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((store) => store.notification.notifications || [])
  const { enqueueSnackbar, closeSnackbar } = useToasts()

  const storeDisplayed = (id) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)]
  }

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(onRemoveNotification(myKey))
          removeDisplayed(myKey)
        },
      })

      // keep track of snackbars that we've displayed
      storeDisplayed(key)
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])
}

export default useNotifier
